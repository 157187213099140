<template>
  <div class="summary-block">
    <div class="title">{{ props.title }}</div>
    <div class="value">
      <span>{{ props.value }}</span
      ><label>{{ props.unit }}</label>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  value: {
    type: [String, Number],
    value: "0",
  },
  unit: {
    type: String,
    value: "",
  },
});
</script>

<style lang="scss" scoped>
.summary-block {
  display: inline-block;
  min-width: 165px;
  padding: 12px 16px;
  box-sizing: border-box;
  background: var(--mx-card-base-bg-color);
  opacity: 1;
  border-radius: 2px;
  & > .title {
    height: 19px;
    font-size: 14px;
    line-height: 19px;
    color: var(--mx-text-secondary-color);
    margin-bottom: 4px;
    white-space: nowrap;
  }
  & > .value {
    white-space: nowrap;
    color: var(--mx-text-base-color);
    & > span {
      height: 27px;
      font-size: 20px;
      line-height: 27px;
    }
    & > label {
      height: 19px;
      font-size: 14px;
      line-height: 19px;
      display: inline-block;
      margin-left: 15px;
    }
  }
}
</style>
