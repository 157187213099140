<script setup lang="ts">
import {
  SystemContent,
  HeadNavigation,
  SwitchStaff,
  UserContent,
  SiderMenu,
  useIntegratedAuthorityList,
} from "@maxtropy/v-components";
import { ref } from "vue";
import { RouterView } from "vue-router";
import { useRouter } from "vue-router";
import { getBase } from "@maxtropy/v-components";
import { getDevMenus } from "@/router/dev-menu";

const router = useRouter();

const contentShow = ref(false);
useIntegratedAuthorityList(list => {
  if (!list) return;
  contentShow.value = true;
});
</script>

<template>
  <header class="header-wrapper" v-if="!getBase()">
    <SystemContent :router="router" />
    <HeadNavigation
      :dev-menu="getDevMenus()"
      custom-class="middle"
      :router="router"
    ></HeadNavigation>
    <div class="header-right">
      <SwitchStaff />
      <el-divider direction="vertical" class="divider" />
      <UserContent />
    </div>
  </header>
  <div class="content-wrapper">
    <SiderMenu :dev-menu="getDevMenus()" :router="router" />
      <router-view v-if="contentShow" />
  </div>
</template>

<style scoped>
.header-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 10px 0 5px;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  background-color: #1b1b1b;

  border-bottom: 2px solid rgba(0, 0, 0, 0);
  border-image: linear-gradient(
    to left,
    transparent,
    #0277bd 45%,
    #2cc4f9,
    #0277bd 55%,
    transparent
  );
  border-image-slice: 1;
}
.divider {
  border-left: 1px solid var(--mx-light-border-color);
}
.header-left {
  display: flex;
  align-items: center;
}
.middle {
  flex: 1 1;
  min-width: 0;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 3px;
  /*width: 250px;*/
}
.content-wrapper {
  display: flex;
  justify-content: flex-start;
  height: calc(100vh - 50px);
}

:deep(.page-content .not-authorized) {
  position: relative;
  font-size: 14px;
  color: var(--mx-text-secondary-color);
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
